import "@/styles/reset.css";
import 'nprogress/nprogress.css';
import "@/styles/globals.css";
import {useState, useEffect} from "react";
import Router, {useRouter} from "next/router";
import NProgress from 'nprogress';
import Cookies from "js-cookie";
import {v4 as uuidv4} from "uuid";
import {motion, AnimatePresence} from "framer-motion";
import {Observable} from 'rxjs';


import PageTransition, {useAsPathWithoutHash} from '@madeinhaus/nextjs-page-transition';
import '@madeinhaus/nextjs-page-transition/dist/index.css';


import CookieConsentRibbon from "@/components/CookieConsentRibbon";
import styles from "@/components/FooterContainer.module.css";
//import { pageView } from "@/utils/Analytics";

NProgress.configure({showSpinner: true});

// Bind the NProgress events to the router events.
Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => {
  NProgress.done();
});
Router.events.on('routeChangeError', () => {
  NProgress.done();
});

// Ensure Observable is available globally
if (typeof globalThis !== 'undefined') {
  globalThis.Observable = Observable;
} else if (typeof window !== 'undefined') {
  window.Observable = Observable;
} else if (typeof global !== 'undefined') {
  global.Observable = Observable;
}


export default function App({Component, pageProps}) {
  const router = useRouter();
  const key = useAsPathWithoutHash();
  const [transitionState, setTransitionState] = useState(null);
  const [isLogoVisible, setIsLogoVisible] = useState(true);


  useEffect(() => {

    const user_id = Cookies.get("user_id");
    if (!user_id) {
      const newUserId = uuidv4();
      Cookies.set("user_id", newUserId, {expires: 365});
    }
    /*
        const handleRouteChange = (url) => {
          //pageView(url);
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
          router.events.off("routeChangeComplete", handleRouteChange);
        };

     */


    const startProgress = async () => {
      setIsLogoVisible(true);
      setTransitionState('initial');
    };

    const stopProgress = async () => {
      return new Promise((resolve) => {
        setIsLogoVisible(false);

        setTimeout(() => {
          setTransitionState('complete');
          resolve();
        }, 200);
      }).then(() => {
        setTimeout(() => {
          setTransitionState(null);
        }, 800);
      });
    };

    Router.events.on('routeChangeStart', startProgress);
    Router.events.on('routeChangeComplete', stopProgress);
    Router.events.on('routeChangeError', stopProgress);

    return () => {
      Router.events.off('routeChangeStart', startProgress);
      Router.events.off('routeChangeComplete', stopProgress);
      Router.events.off('routeChangeError', stopProgress);
    };
  }, []);


  const getAnimation = () => {
    switch (transitionState) {
      case 'initial':
        return {x: '0%', skewX: '0deg'};
      case 'complete':
        return {x: '110%', skewX: '-10deg'};
      default:
        return {x: '-110%', skewX: '-10deg'};
    }
  };


  return (
    <>
      {/*<AnimatePresence mode='wait'>
        <motion.div key={router.route} initial="pageInitial" animate="pageAnimate" exit="pageExit" variants={{
          pageInitial: {
            opacity: 0,
          },
          pageAnimate: {
            opacity: 1,
          },
          pageExit: {
            opacity: 0,
          },
        }}>*/}


      {transitionState && (
        <AnimatePresence mode='wait'>
          <div className="transition-overlay">
            {isLogoVisible && (
              <div className="logo">
                <img className={styles.smallLogoIcon} alt="" src="/smalllogo.svg"/>
                <span>CHARGING</span>
              </div>
            )}
            <motion.div
              initial={{x: '-110%', skewX: '-10deg'}}
              animate={getAnimation()}
              exit={{x: '-110%', skewX: '10deg'}}
              transition={{duration: 0.6, ease: 'easeOut'}}
              className="skew-div"
            ></motion.div>

          </div>
        </AnimatePresence>
      )}
      <PageTransition inPhaseDuration={600} outPhaseDuration={70}>
        <Component {...pageProps} key={key}/>
      </PageTransition>
      {/*</motion.div>
      </AnimatePresence>*/}
      <CookieConsentRibbon/>
    </>
  );
}
